

<template>
  <footer class="footer" :class="{ 'hide-footer': !showFooter }">
    <div class="footer-content">
      <p>&copy; 2024 Cognito AI. All rights reserved.</p>
      <div class="social-icons">
        <a href="#" class="icon" target="_blank"><i class="fab fa-twitter"></i></a>
        <a href="#" class="icon" target="_blank"><i class="fab fa-linkedin"></i></a>
        <a href="#" class="icon" target="_blank"><i class="fab fa-instagram"></i></a>
      </div>
    </div>
  </footer>
</template>

<script>
export default {};
</script>

<style scoped>
/* Mobile Styles */
@media (max-width: 900px) {
  .footer {
    /* width: 66% !important; */
    margin-bottom: 80px !important;
  }

  .footer-content {
    max-width: 100%; /* Adjust this value as needed */
    margin: 0 auto;
  }
}

.footer {
  background-color: black;
  color: #fff;
  padding: 20px;
  text-align: center;
  display: flex;
  width: 100%;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.footer-content {
  max-width: 100%; /* Adjust this value as needed */
  margin: 0 auto;
}

.hide-footer {
  transform: translateY(100%);
}

.footer p {
  margin: 0;
  font-size: 1rem;
  margin-bottom: 10px;
}

.social-icons {
  display: flex;
  justify-content: center;
}

.icon {
  font-size: 1.5rem;
  color: #fff;
  margin: 0 10px;
  transition: color 0.3s ease;
}

.icon:hover {
  color: #f39c12;
}
</style>

