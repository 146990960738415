<template>
  <div id="app" class="app-container">
    <Navbar />
    
    <div class="content-container">
      <transition name="fade" mode="out-in">
        <router-view />
      </transition>
    </div>

    <Footer />
  </div>
</template>

<script>
import Navbar from './components/Navbar.vue';
import Footer from './components/Footer.vue';

export default {
  components: {
    Navbar,
    Footer
  }
};
</script>

<style>
@import '~mdb-ui-kit/css/mdb.min.css';
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap');

html, body, #app {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: #f8f8f8;
  color: #000000;
  margin: 0;
  padding: 0;
  font-family: 'Roboto', sans-serif;
}

.content-container {
  flex: 1;
}

.fade-enter-active, .fade-leave-active {
  transition: opacity 0.5s ease;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
}

/* Media queries for responsive adjustments */
@media only screen and (max-width: 553px) {
  .w3-animate-left, .w3-animate-right {
    animation: none;
  }
}
</style>
