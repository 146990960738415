<template>
  <div class="full-page-container">
    <section class="landing-section">
      <div class="content-wrapper">
        <h1 class="main-heading animate-charcter">FROM DATA TO INSIGHT</h1>
        <p class="sub-heading">
          Stop hopping between data tools, struggling with reproducibility, or
          taking screenshots. EDA & ETL made easy with Code, No-code & AI.
        </p>
        <button
          class="btn btn-read-more colorful-border"
          @click="scrollToContact"
        >
          Read More
        </button>
      </div>
    </section>

    <section class="contact-section" ref="contact">
      <div class="contact-content">
        <h1 class="contact-title">Contact</h1>
        <p class="contact-tagline">Drop me a line here:</p>
        <form
          class="contact-form"
          action="https://formspree.io/f/xbjnoeze"
          method="POST"
        >
          <div class="form-group">
            <input
              type="text"
              class="form-control"
              id="exampleInputName"
              placeholder="Name"
              name="Name"
              v-model="name"
            />
          </div>
          <div class="form-group">
            <input
              type="email"
              class="form-control"
              id="exampleInputEmail1"
              placeholder="Email"
              name="_replyto"
              v-model="email"
            />
          </div>
          <div class="form-group">
            <textarea
              class="form-control"
              rows="5"
              placeholder="Message"
              name="Message"
              v-model="message"
            ></textarea>
          </div>
          <button type="submit" class="btn btn-custom submit">
            Send Message
          </button>
        </form>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  data() {
    return {
      name: "",
      email: "",
      message: "",
    };
  },
  methods: {
    scrollToContact() {
      this.$refs.contact.scrollIntoView({ behavior: "smooth" });
    },
  },
};
</script>

<style scoped>
/* Base Styles */
html,
body,
#app {
  min-height: 100vh;
  margin: 0;
  padding: 0;
}

body {
  font-family: "Roboto", sans-serif;
  background-color: #ffffff;
  color: #000000;
}

/* Navbar Styles */
.navbar {
  background-color: #3498db;
  padding: 10px;
  text-align: left;
  color: #fff;
}

.navbar-brand {
  font-size: 1.5rem;
  font-weight: bold;
}

/* Button Styles */
.btn-read-more {
  background-color: #fff;
  color: #000;
  padding: 15px 30px;
  border: 2px solid #f39c12;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
  width: 20%;
  justify-content: center;
  margin: 0 auto; /* Center the button */
  margin-top: 20px; /* Adjusted margin for better spacing */
}

.btn-read-more:hover {
  background-color: #f39c12;
  color: #fff;
}

/* Landing Section Styles */
.landing-section {
  /* margin: 15%; */
  background-color: #f8f8f8;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 93vh;
}

.content-wrapper {
  width: 90%;
  max-width: 1200px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 auto;
}

.main-heading {
  font-size: 3rem;
  margin-bottom: 1rem;
  width: 100%;
  font-weight: 900;
}

.sub-heading {
  font-size: 1.5rem;
  margin-bottom: 2rem;
  color: #555;
  width: 80%;
  text-align: center;
  margin: 0 auto; /* Center the text */
}

.animate-charcter {
  text-transform: uppercase;
  background-image: linear-gradient(
    -225deg,
    #231557 0%,
    #44107a 29%,
    #ff1361 67%,
    #fff800 100%
  );
  background-size: auto auto;
  background-clip: border-box;
  background-size: 200% auto;
  color: #fff;
  background-clip: text;
  text-fill-color: transparent;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: textclip 8s linear infinite;
  display: inline-block;
}

@keyframes textclip {
  to {
    background-position: 200% center;
  }
}

/* Contact Section Styles */
.contact-section {
  background-color: #f8f8f8;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* padding: 80px; */
  height: 50vh;
  margin-top: 20vh;
}

.contact-content {
  max-width: 80%;
}

.contact-title {
  font-size: 3rem;
  margin-bottom: 1rem;
  color: black;
  width: 600px;
}

.contact-tagline {
  font-size: 1.5rem;
  color: black;
}

.contact-form {
  width: 100%;
  /* margin-top: 25%; */
  margin-bottom: 25%;
}

.form-group {
  margin-bottom: 20px;
}

.form-control {
  width: 100%;
  padding: 15px;
  font-size: 1rem;
  border: 1px solid #ccc;
  border-radius: 5px;
}

textarea {
  height: 150px;
}

.submit {
  background-color: black;
  color: #fff;
  padding: 15px 30px;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.submit:hover {
  background-color: #f39c12;
  color: #fff;
}


/* Mobile Styles */
@media (max-width: 768px) {
  .content-wrapper {
    width: 90%;
    max-width: 100%;
    height: auto; /* Adjusted height for smaller screens */
  }

  .main-heading {
    font-size: 2.5rem; /* Adjusted font size for smaller screens */
  }

  .sub-heading {
    font-size: 1.2rem;
    margin: 0 auto;
  }

  .btn-read-more {
    padding: 12px 24px;
    width: 100%; /* Full width for smaller screens */
    margin-top: 20px;
  }

  .contact-section {
    padding: 20px;
    margin-top: 0px;
    /* margin-bottom: 80px; */
  }

  .contact-title {
    font-size: 2rem;
  }

  .contact-tagline {
    font-size: 1rem;
    margin-bottom: 1.5rem;
  }

  .form-control {
    font-size: 0.8rem;
  }

  .submit {
    padding: 12px 24px;
  }
}
</style>
