<template>
    <nav class="navbar">
      <div class="navbar-container">
        <router-link to="/">
          <img src="/logo-black.png" alt="Logo" class="logo" />
        </router-link>
  
        <!-- Add any additional navigation items here -->
  
      </div>
    </nav>
  </template>
  
  <script>
  export default {};
  </script>
  
  <style scoped>
  .navbar {
    background-color: #ffffff;
    padding: 10px 20px;
  }
  
  .navbar-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .logo {
    max-height: 40px;
    max-width: 100%;
  }
  </style>
  