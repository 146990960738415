import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'
// import About from '../views/About.vue'
// import Contact from '../views/contact.vue'
// import Skills from '../views/Skills.vue'
// import Portfolio from '../views/Portfolio.vue'



const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  // {
  //   path: '/about',
  //   name: 'About',
  //   component: About
  // },
  // {
  //   path: '/contact',
  //   name: 'Contact',
  //   component: Contact
  // },
  // {
  //   path: '/skills',
  //   name: 'Skills',
  //   component: Skills
  // },
  // {
  //   path: '/portfolio',
  //   name: 'Portfolio',
  //   component: Portfolio
  // }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
